interface ExperienceData {
  position: string;
  dates: string[];
  company: string;
  tech: string[];
  bullets: string[];
  media: IMedia[];
}

export interface IMedia {
  type: MediaType;
  title: string;
  source: string;
}

export enum MediaType {
  YOUTUBE = 'youtube',
  IMG = 'image',
  GITHUB = 'github',
  USGS = 'usgs',
  SPOTIFY = 'spotify',
}

export const data: ExperienceData[] = [
  {
    position: 'Program Director, Internal Applications',
    dates: ['2022', 'Present'],
    company: 'H&R Block',
    tech: [
      'TypeScript',
      'Node.js',
      'Express.js',
      'Cosmos DB',
      'GraphQL',
      'React',
      'Redux',
      'Jest',
      'React Testing Library',
      'Power Automate',
      'Figma',
    ],
    bullets: [
      'Impacting 10,000+ associates with custom internal enterprise business intelligence and admin content management systems with 100% uptime and over $2,000 per month reduction in costs.',
      'Prototyping an AI assistant browser extension for Azure DevOps to enhance DevX and productivity.',
      'Enhanced productivity by 20% by implementing Scrum, decoupling and reducing complexity, developing internal documentation, and standardizing developer workflow tools.',
      'Reduced turnaround time from 2 hours to 2 seconds by overseeing the development of a generative AI chatbot that queries internal data and delivers summaries and visualizations.',
      "Managed end-to-end development lifecycle of a generative AI chatbot that queries users' files.",
      'Managed UI/UX for an AI tax preparation prototype projected to save 70% in tax preparation time.',
      'Yielded 33% reduction in projected costs by delivering environment migration and code consolidation project 30 days early with 0 bugs, reducing 2-day change requests into 60 seconds.',
      'Developed real user monitoring prototype with user session replay and aggregated heat maps.',
      'Revamped API and UI architecture and developer tooling leading to 100% code coverage.',
      'Facilitated 4,000+ submissions of reinforcement learning for ML models by overseeing the design, development, release, and teardown of an internal grading application.',
      'Leveraged Azure DevOps and adapted Scrum rituals to synchronize developers separated by 10 time zones while reporting directly to the CFO and leading 4 employees and 1 contractor.',
      'Panelist for promotion boards leading to 3 promotions to lead and 2 promotions to manager.',
    ],
    media: [
      {
        type: MediaType.IMG,
        title: 'Data Science & Analytics Hub',
        source: 'dsa-hub.webp',
      },
      {
        type: MediaType.IMG,
        title: 'Session Replay',
        source: 'session-replay.webp',
      },
      {
        type: MediaType.IMG,
        title: 'Azure DevOps Assistant response',
        source: 'ada-response.webp',
      },
      {
        type: MediaType.IMG,
        title: 'Azure DevOps Assistant without data',
        source: 'ada-no-data.webp',
      },
    ],
  },
  {
    position: 'Web Developer & Graphic Designer',
    dates: ['2013', 'Present'],
    company: 'Freelance',
    tech: [
      'TypeScript',
      'MongoDB',
      'Mongoose',
      'Node.js',
      'Express.js',
      'React',
      'Redux',
      'Bootstrap',
      'AWS',
      'Adobe',
    ],
    bullets: [
      'Developed full-stack products for a small business using AWS, featuring a recipe blog, a drag-and-drop image scramble game, and a custom admin content management system.',
      'Delivered a graphical web application to monitor monthly expenses for product owners.',
      'Led project management of design and vendor fulfillment for wedding and small business products.',
    ],
    media: [
      {
        type: MediaType.IMG,
        title: 'Camp and a Cuppa',
        source: 'camp-and-a-cuppa.jpeg',
      },
      {
        type: MediaType.IMG,
        title: 'Wedding Invitation',
        source: 'wedding-invitation.webp',
      },
      {
        type: MediaType.IMG,
        title: 'Blonde Sugar Skull',
        source: 'blonde-sugar-skull.jpeg',
      },
      {
        type: MediaType.YOUTUBE,
        title: 'Doll',
        source: '7Rd-K9QlEik',
      },
      {
        type: MediaType.IMG,
        title: 'Natural Energy Law logo',
        source: 'natural-energy-law-logo.webp',
      },
      {
        type: MediaType.IMG,
        title: 'My Wife',
        source: 'my-wife.webp',
      },
      {
        type: MediaType.YOUTUBE,
        title: 'Only You',
        source: 'LfKAxTpG0sY',
      },
      {
        type: MediaType.IMG,
        title: 'Flow',
        source: 'flow.webp',
      },
      {
        type: MediaType.IMG,
        title: 'Illusions',
        source: 'illusions.jpeg',
      },
      {
        type: MediaType.IMG,
        title: 'Caffeine Crime Scene',
        source: 'caffeine-crime-scene.jpeg',
      },
      {
        type: MediaType.IMG,
        title: 'Hot Ones',
        source: 'hot-ones.webp',
      },
      {
        type: MediaType.IMG,
        title: 'Scotch',
        source: 'scotch.webp',
      },
      {
        type: MediaType.IMG,
        title: 'Peach & Eggplant',
        source: 'peach-eggplant.webp',
      },
      {
        type: MediaType.IMG,
        title: 'Nude',
        source: 'nude.webp',
      },
      {
        type: MediaType.IMG,
        title: 'Orchid',
        source: 'orchid.webp',
      },
      {
        type: MediaType.IMG,
        title: 'Peacock Feathers',
        source: 'peacock-feathers.webp',
      },
      {
        type: MediaType.IMG,
        title: 'Baker Park',
        source: 'baker-park.webp',
      },
      {
        type: MediaType.YOUTUBE,
        title: 'Chicken & Pig',
        source: 'RVrj_A3uHuA',
      },
      {
        type: MediaType.IMG,
        title: 'Scream',
        source: 'scream.webp',
      },
      {
        type: MediaType.YOUTUBE,
        title: 'Mosaic',
        source: 'e4mPkI8JIfk',
      },
      {
        type: MediaType.YOUTUBE,
        title: 'AI Communication Coach',
        source: 'RJUtkKnL1Nw',
      },
      {
        type: MediaType.GITHUB,
        title: 'Memoji',
        source: 'memoji',
      },
      {
        type: MediaType.YOUTUBE,
        title: 'Graphical Expense Tracker',
        source: 'JR3ExrJVkgg',
      },
      {
        type: MediaType.YOUTUBE,
        title: 'Data Visualization Dashboard',
        source: 'ZVvO2nj2gUw',
      },
      {
        type: MediaType.IMG,
        title: 'Piecemeal',
        source: 'piecemeal.jpeg',
      },
      {
        type: MediaType.IMG,
        title: 'Ingredient Multiplier',
        source: 'ingredient-multiplier.webp',
      },
      {
        type: MediaType.IMG,
        title: 'Nutrition Facts',
        source: 'nutrition-facts.webp',
      },
      {
        type: MediaType.IMG,
        title: 'Admin CMS',
        source: 'at-ease-recipes.jpeg',
      },
    ],
  },
  {
    position: 'Software Engineer',
    dates: ['2021', '2022'],
    company: 'Guaranteed Rate',
    tech: [
      'TypeScript',
      'Node.js',
      'Express.js',
      'React',
      'Redux',
      'TailwindCSS',
      'Chart.js',
      'Jest',
      'React Testing Library',
    ],
    bullets: [
      'Built an automated browser screenshot uploader for unexpected errors, allowing users to send what they see as part of a logging strategy.',
      'Delivered an Express mock API with server-sent events to automatically refresh the browser when API changes were saved to minimize manual browser reload.',
      'Achieved 100% code coverage for all front-end business logic by developing robust test suites.',
      'Accelerated the production release from 0-to-MVP in 6 months by making key contributions and implementing engineering best practices.',
      'Led frontend feature development for a new flagship, customer-facing product, including account creation, SSN verification, and home valuation charts.',
    ],
    media: [
      {
        type: MediaType.GITHUB,
        title: 'Screenshot Uploader',
        source: 'screenshot-uploader',
      },
      {
        type: MediaType.GITHUB,
        title: 'Home Valuation Chart',
        source: 'home-valuation-chart',
      },
    ],
  },
  {
    position: 'Developer',
    dates: ['2020', '2021'],
    company: 'Virtual Service Operations',
    tech: [
      'TypeScript',
      'MongoDB',
      'Node.js',
      'GraphQL',
      'Express.js',
      'NestJS',
      'Angular',
    ],
    bullets: [
      'Generated over $40,000 in revenue within first month via velocity as a service.',
      'Implemented role-based navigation via server-side rendering with CRUD admin form for modifications.',
      'Automated the manual frontend build process across 6 modules reducing build time by 1 hour.',
    ],
    media: [],
  },
  {
    position: 'Visual Information Specialist',
    dates: ['2018', '2020'],
    company: 'U.S. Geological Survey',
    tech: [
      'Adobe Illustrator',
      'Adobe Photoshop',
      'Adobe InDesign',
      'JavaScript',
    ],
    bullets: [
      'Advanced publishing lifecycle of 30+ digital and print products, including a Congressional Circular.',
      'Built a website prototype allowing stakeholders to monitor the status of digital products.',
      'Created and administered the product brief for Section 508-compliant print and digital products.',
      'Established the product review process for stakeholders across the organization.',
    ],
    media: [
      {
        type: MediaType.USGS,
        title:
          'Drinking Water Health Standards Comparison and Chemical Analysis of Groundwater for 72 Domestic Wells in Bradford County, Pennsylvania, 2016',
        source: 'sir20185170',
      },
      {
        type: MediaType.USGS,
        title:
          'Effective Solubility Assessment for Organic Analytes in Liquid Samples, BKK Class I Landfill, West Covina, California, 2014-16',
        source: 'ofr20191080',
      },
      {
        type: MediaType.USGS,
        title:
          'Gap Analysis Project (GAP) Terrestrial Vertebrate Species Richness Maps for the Conterminous U.S.',
        source: 'sir20195034',
      },
      {
        type: MediaType.USGS,
        title:
          'Drought forecasting for streams and groundwaters in northeastern United States',
        source: 'fs20193015',
      },
      {
        type: MediaType.USGS,
        title:
          'Geomorphic characteristics of Tenmile Creek, Montgomery County, Maryland, 2014-16',
        source: 'sir20185098',
      },
      {
        type: MediaType.USGS,
        title: 'Hurricane Sandy Impacts on Coastal Wetland Resilience',
        source: 'ofr20181142',
      },
      {
        type: MediaType.USGS,
        title:
          'Spatial Distribution of Nutrients, Chloride, and Suspended Sediment Concentrations and Loads Determined by Using Different Sampling Methods in a Cross Section of the Trenton Channel of the Detroit River, Michigan, November 2014-November 2015',
        source: 'sir20185141',
      },
      {
        type: MediaType.USGS,
        title: 'U.S. Geological Survey (USGS) Water-Use Websites',
        source: 'fs20183044',
      },
      {
        type: MediaType.USGS,
        title:
          'Agricultural Conservation Practice Implementation in the Chesapeake Bay Watershed Supported by the U.S. Department of Agriculture',
        source: 'ds1102',
      },
      {
        type: MediaType.USGS,
        title:
          'An initial comparison of pesticides and amphibian pathogens between natural and created wetlands in the New Jersey Pinelands, 2014-16',
        source: 'ofr20181077',
      },
      {
        type: MediaType.USGS,
        title:
          'Water Priorities for the Nation—The U.S. Geological Survey Next Generation Water Observing System',
        source: 'fs20193046',
      },
      {
        type: MediaType.IMG,
        title: 'Amphibian Research & Monitoring Initiative',
        source: 'usgs-armi.jpeg',
      },
      {
        type: MediaType.IMG,
        title: 'USGS Coin',
        source: 'usgs-coin.jpeg',
      },
    ],
  },
  {
    position: 'Cavalry Scout',
    dates: ['2012', '2015'],
    company: 'U.S. Army',
    tech: [],
    bullets: [
      'Maintained $2.3M+ in product assets while promoting accountability throughout the team. ',
      'Created a 40-page publication featuring illustrations of products, processes, and operations.',
      'Trained 3 team members on product safety and operations, leading to promotions for each.',
      'Assisted asset audits with leadership for products such as weapons, optics, and vehicles.',
    ],
    media: [],
  },
  {
    position: 'Musician',
    dates: ['2002', '2011'],
    company: 'Fools & Horses, LLC',
    tech: [],
    bullets: [
      'Launched 4 full-length digital products 100% on time and within budget by contracting, coordinating, and collaborating with producers, designers, and vendors. ',
      'Produced a 200% increase in sales revenue by collaborating with a marketing firm to overhaul product designs and drive product visibility and user growth.',
      'Boosted revenue by 10% by negotiating performance contracts and assisting in developing press releases and marketing for tours.',
      'Ensured production success by hiring and coordinating resources for the production of videos, including a helicopter, vehicles, venues, director, and actors.',
      'Featured music video on Fuse TV and music featured on MTV, A&E, E!, and USA networks.',
    ],
    media: [
      {
        type: MediaType.YOUTUBE,
        title: '"3 Chords" Music Video',
        source: '32va-LZ0VTg',
      },
      {
        type: MediaType.SPOTIFY,
        title: '"Fools & Horses" Album (2010)',
        source: '3WOYK8ri6e0LVXUP2TzdQX',
      },
      {
        type: MediaType.SPOTIFY,
        title: '"I Am the Ghost" Album (2008)',
        source: '3cYqxvG3oqW3ycsBY6qwYi',
      },
      {
        type: MediaType.SPOTIFY,
        title: '"Pop Filter" Album (2006)',
        source: '74CraLuG2gJ8gibyWXdaHP',
      },
      {
        type: MediaType.SPOTIFY,
        title: '"Pop Filter - Rethoughts" Album (2006)',
        source: '2bmjpeilZ5TZyB5GeOFrxN',
      },
      {
        type: MediaType.SPOTIFY,
        title: '"Saturday Morning" Album (2004)',
        source: '6EjpogQNmkidlbV0fmWaBH',
      },
      {
        type: MediaType.IMG,
        title: 'Fools & Horses Promo Poster',
        source: 'fools-and-horses.jpeg',
      },
    ],
  },
];

export const highlights = [
  {
    category: 'Leadership & Project Management',
    bulletPoints: [
      'Delivered custom-built internal enterprise business intelligence and content management systems, impacting 10,000+ associates with 100% uptime and reducing costs by $2,000+ per month.',
      'Led a team of 4 employees and 1 contractor across 10 time zones to deliver an environment migration and code consolidation project 30 days early, achieving a 33% reduction in projected costs and cutting change request time from 2 days to 60 seconds.',
      'Enhanced productivity by 20% through the implementation of Scrum methodologies, developing internal documentation, and standardizing developer workflow tools.',
    ],
  },
  {
    category: 'Technical Innovation & System Optimization',
    bulletPoints: [
      'Developed real user monitoring prototype featuring session replay and aggregated heat maps, providing deeper insights into user behavior and improving system performance.',
      'Built an automated browser screenshot uploader for unexpected errors, enabling real-time logging and submission, significantly enhancing system diagnostics and user experience.',
      'Achieved 100% code coverage for new API and UI codebases by promoting best practices in testing and automation using Jest and React Testing Library.',
    ],
  },
  {
    category: 'AI & ML Development',
    bulletPoints: [
      'Prototyping an AI assistant browser extension for Azure DevOps to enhance DevX and productivity.',
      'Directed the end-to-end development lifecycle of a generative AI chatbot that queries internal data, reducing turnaround time from 2 hours to 2 seconds, and boosting internal efficiency.',
      'Led a cross-functional team to build and integrate UI/UX for an AI-driven tax preparation prototype, projected to save 70% in preparation time, significantly improving user experience.',
    ],
  },
];
